.modal-wrapper {
    position: absolute;
    width: 100%;
    max-height: 90vh;
    overflow: scroll;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    box-shadow: 24px;
    background-color: white;
}

@media (max-width: 1400px) {
    .modal-wrapper {
        width: 90%;
    }
}

@media (min-width: 1400px) {
    .modal-wrapper {
        width: 75%;
    }
}