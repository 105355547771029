.column {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    min-width: 20%;
}

.title {
    font-size: 24px;
    color: white;
}

.title, .link {
    color: #EFEFEF;
}

.title, .link:not(:last-child) {
    margin-bottom: 26px;
}

@media (min-width: 992px) {
    .column {
        align-items: flex-start;
        margin-bottom: 0;
    }
}


