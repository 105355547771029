.custom-button {
    /* display: inline-block; */
    padding: 16px 47px 16px 47px;
    border: none;
    color: #fff;
    font-family: 'Lora', sans-serif;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
  }
  
  .custom-button span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .primary {
    background-color: var(--xorange);
    border: 1px solid var(--xorange);
    transition: background-color 0.3s ease, border 0.3s ease, color 0.3s ease;
  }
  /* test transition effect */
  .primary:hover {
    border: 1px solid var(--xorange);
    color: var(--xorange);
    background-color: white;
  }

  .secondary {
    background-color: var(--xdarkblue);
    border: 1px solid var(--xdarkblue);
  }

  .outline-primary {
    border: 1px solid var(--xorange);
    color: var(--xorange);
    background-color: white;
  }