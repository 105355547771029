body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
@font-face {
  font-family: 'Manrope';
  font-weight: 500;
  src: url('../src/fonts/Manrope/static/Manrope-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Lora';
  font-weight: 500;
  src: url('../src/fonts/Lora/static/Lora-Medium.ttf') format('truetype');
}

/* Variables */
:root {
  /* colors */
  --xblue: #4E8EC2;
  --xdarkblue: #08264A;
  --xverylightgrey: #F2F2F2;
  --xlightgrey: #F8F8F8;
  --xgrey: #505050;
  --xdark: #181818;
  --xorange: #FF6F12;
  /* / colors */

  /* Bootstrap breakpoints */
  --sm: 576px;
  --md: 768px;
  --lg: 992px;
  --xl: 1200px;
  --xxl: 1500px;
  /* / Bootstrap breakpoints */
}
/* / Variables */