.amenities-section {
    padding: 42px;
    background-color: var(--xverylightgrey);
}
.amenity {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--xblue);
}

.amenity:not(:last-child) {
    margin-bottom: 24px;
}

@media (min-width: 576px) {
    .amenity {
        width: 45%;        
    }
    .amenity:not(:last-child) {
        margin-bottom: 0;
    }
    .amenity:not(:nth-last-child(-n+2)) {
        margin-bottom: 24px;
    }
    .amenities-section {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 42px;
    }
}