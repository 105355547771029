.payment-info-wrapper {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--xblue);
    padding: 32px;
}

.close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 36px;
    cursor: pointer;
    z-index: 10;
}

.payment-modal-wrapper {
    position: relative;
    font-family: "Arial";
}

.hero-title {
    position: absolute;
    left: 42px;
    bottom: 42px;
    font-size: 42px;
    color: white;
}

.main-section {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 32px;
}
.main-section > * {
    width: 100%;
}

.main-section > *:not(:last-child) {
    margin-bottom: 40px;
}

@media (min-width: 992px) {
    .main-section {
        flex-direction: row;
        justify-content: space-between;
        /*padding: 42px 42px 82px 42px;*/
    }
    .main-section > div:last-child {
        /*width: 48%;*/
        padding-left: 30px;
    }
}

/* Payment Complete */

.payment-done-wrapper {
    position: relative;
    background-color: white;
    padding: 56px 120px 100px; 
    text-align: center;
}

.payment-done-wrapper > *:not(:last-child) {
    margin-bottom: 64px;
}

.icon-section {
    display: flex;
    justify-content: center;    
}

.details-section {

}

.details-box {
    border-top: 1px solid var(--xblue);
    border-bottom: 1px solid var(--xblue);
    padding: 24px 0;
}

.details-title {
    color: var(--xblue);
    margin-bottom: 44px;
}

.details-item {
    display: flex;
    justify-content: space-between;
}

.details-item:not(:last-child) {
    margin-bottom: 24px;
}

.email-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.conditions-checkbox {
    margin: 32px 0;
}
.conditions-checkbox {
    font-size: 14px;
}
.submit-section {
    width: 100%;
}
.submit-section > * {
    width: 100%;
}
