.card {
    /* height: 812px; */
    border:    1px solid var(--xblue);
    /* min-width: 436px; */
    max-width: 100%;
    padding:   32px;
    width:     436px;
}

.card > *:not(:last-child) {
    margin-bottom: 32px;
}

.row-title {
    color: var(--xblue);
}

.title {
    font-size: 28px;
}

.destination-row {
    display:         flex;
    justify-content: space-between;
    width:           100%;
}

.destination-box {
    width: 100%;
}

.adult-children-row {
    display:         flex;
    justify-content: space-between;
}

.adult-children-row > div {
    width: 49%;
}

.adult-children-row > div:first-child {
    margin-right: 1px solid grey;
}

.dates-row > div {
    display:         flex;
    justify-content: space-between;
}

.date-picker-box {
    width: 49%;
}

.row-title {
    margin-bottom: 18px;
}

.rooms-item-row {
    display:         flex;
    justify-content: space-between;
}

.rooms-item-box {
    width: 49%;
}

.amenities-row {

}

.button-box {
    width: 100%;
}

.button-box > button {
    width: 100%;;
}

@media (min-width: 768px) {
    .card {
        margin-right: 32px;
    }
}