.container {
    display: none;
   
}

.img-wrapper {
    height: 180px;
    width: 329px;
    overflow: hidden;
    margin-right: 28px;
}

.img {
    object-fit: cover;
}

@media (min-width: 992px) {
    .container {
        display: flex;
        justify-content: flex-start;
        position: absolute;
        left: 28px;
        /* TEMP */
        top: -55%;
        transform: translate(0, 50%);   
    }

}