.title {
    margin-bottom: 32px;
    color: var(--xdark);
}
.info-box {    
    flex-grow: 1;
}
.row {
    display: flex;
    justify-content: space-between;
}
.row * {
    color: var(--xdark);
}
.row:not(:last-child) {
    margin-bottom: 18px;
}
.row:last-child {
    margin-bottom: 32px;
}

.row-item {    
    width: 100%;
    position: relative;
}

.row-title {
    margin-bottom: 18px;
    color: var(--xblue);
}

.total.row {
    margin-bottom: 45px;
}
.total * {
    font-size: 18px;
}
.total-label {
    color: var(--xblue);
}

.expiry-cvv-row {
    display: flex;
    flex-direction: column;
}

.expiry-cvv-row .row-item {
    width: 100%;
} 

.expiry-cvv-row .row-item:not(:last-child) {
    margin-bottom: 18px;
}

@media (min-width: 576px) {
    .expiry-cvv-row .row-item {
        width: 49%;
    }   
    .expiry-cvv-row {
        flex-direction: row;
    }
    .expiry-cvv-row .row-item:not(:last-child) {
        margin-bottom: 0;
    }
}
