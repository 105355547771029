/* Reset CSS */

/* Box sizing */
html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

/* Body */
body {
    font-family: Arial, sans-serif;
    margin:      0;
    padding:     0;
}

/* Headings */
h1, h2, h3, h4, h5, h6 {
    font-family: Lora;
    font-size:   inherit;
    font-weight: 500;
    font-weight: inherit;
    margin:      0;
}

h1 {
    font-size: 64px;
}

h2 {
    font-size: 42px;
}

h3 {
    font-size: 28px;
}

body {
    font-family: Manrope, serif;
}

/* Paragraphs */
p {
    margin: 0;
}

/* Links */
a {
    color:           inherit;
    text-decoration: none;
}

/* Lists */
ul, ol {
    list-style: none;
    margin:     0;
    padding:    0;
}

/* Forms */
input, textarea, select, button {
    font-family: inherit;
    font-size:   inherit;
    margin:      0;
    padding:     0;
}

/* Images */
img {
    height:    auto;
    max-width: 100%;
}

/* Tables */
table {
    border-collapse: collapse;
    border-spacing:  0;
}

/* Clearfix */
.clearfix::before,
.clearfix::after {
    content: "";
    display: table;
}

.clearfix::after {
    clear: both;
}

.datepicker__month-day--selected {
    background-color: #FFAE7BFF !important;
}

.datepicker__close-button, .datepicker__clear-button, .datepicker__submit-button, .datepicker__month-day--first-day-selected, .datepicker__month-day--last-day-selected {
    background-color: var(--xorange) !important;
}

.datepicker__inner {
    width: 100%;
}
  