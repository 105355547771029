.underhero-section {
    background-color: var(--xdarkblue);
    padding: 42px;
}



.label {
    color: var(--xblue);
    font-size: 14px;
    margin-bottom: 18px;
}

.value {
    color: white;
    font-size: 24px;
}

@media (min-width: 768px) {
    .underhero-section {
        display: flex;
        justify-content: flex-start;
    }
    .item {
        margin-right: 30px;
    }
}

@media (min-width: 1200px) {
    .item {
        margin-right: 100px;
    }
}

@media (min-width: 1400px) {
    .underhero-section {
        padding: 42px;
    }
    .item {
        /*margin-right: 200px;*/
    }
}