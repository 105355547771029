.wrapper {
    position: absolute;
    width: 714px;
    top: 192px;
    left: 10%;
}

.title {
    font-size: 32px;
    font-weight: 500;
    color: white !important;
    margin-bottom: 0px;
    width: 150px;
    text-shadow: 1px 1px 1px black;
}

.button-pane {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.button-pane > *:not(:last-child) {
    margin-bottom: 26px;
}


@media (min-width: 768px) {
    .title{
        width: 100%;
        font-size: 48px;
    }
    .button-pane {
        flex-direction: row;

    }

    .button-pane > *:not(:last-child) {
        margin-right: 26px;
    }
}

@media (min-width: 992px) {
    .wrapper {
        left: 260px; 
    }
    .title {
        font-size: 64px
    }
}