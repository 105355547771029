.control-bar {
    padding: 24px 0;
    margin-top: -125px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.switch-wrapper {
    display: flex;
    justify-content: flex-start;
}
.switch-wrapper div {
    cursor: pointer;
}
.switch-wrapper div:not(:last-child) {
    margin-right: 36px; 
    
}   
.control-bar div * {
    font-size: 18px;
}

.active * {
    color: var(--xblue);
}

.order {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    min-width: 350px;
}

.control-bar div span * {
    margin-right: 12px; 
}

@media (min-width: 992px) {
    
}

@media (min-width: 1400px) {
    .control-bar {
        padding: 24px 0;
        margin-top: -100px;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;
    }     
    .order {
        margin-left: auto;
        margin-right: 0; 
    }
}