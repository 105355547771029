.title {
    margin-bottom: 38px;
}

.container {
    display: grid;
    grid-template-columns: repeat(1, 1fr); 
    gap: 10px; 
    width: 100%;
}

.container * {
    color: var(--xgrey);
}

.item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.icon {
    margin-right: 12px;
}

@media (min-width: 576px){
    .container {
        grid-template-columns: repeat(2, 1fr); 
        width: 685px;
    }
}