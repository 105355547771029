.gallery {
    margin: 0;
}
.title {    
    color: white !important;
    margin-bottom: 38px;    
    font-weight: 500;
    line-height: 50px;
    letter-spacing: 0em;
}
.container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    grid-gap: 28px;
    max-width: calc(391px * 2 + 28px);
    /*padding-bottom: 86px;*/
    /*margin-bottom: 86px;*/
    /*border-bottom: 1px solid grey;*/
  }
  
  .wrapper {
    width: 391px;
    max-width: 100%;
    height: 240px;
  }
  
  .img {
    width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }

.includes {
    color: white;
    text-align: center;
}  
.includes-title {
  display: inline-block;
  margin-bottom: 68px;
}

.amenities {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.amenity {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100px;
}

.amenity:not(:last-child) {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .amenities {
    flex-direction: row;
    justify-content: flex-start;
  }
  .amenity:not(:last-child) {
    margin-right: 86px;
  }
  .container {
    grid-template-columns: repeat(auto-fit, minmax(391px, 1fr));
  }
}