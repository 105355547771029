.container {
    width: 932px;
    height: 100%;
    position: relative;
}

.map-container {
    width: 100%;
    height: 100%;
}

.loader-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.listing-item-wrapper {
    position: fixed;
    z-index: 200;

}