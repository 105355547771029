.hero-section {
    min-width: 100%;
    height: 600px;
    position: relative;
    display: flex; 
    align-items: center; 
    justify-content: center; 
    overflow: hidden; 
}

.hero-image {
    width: 100%; 
    height: 100%;
    object-fit: cover;
}

.hero-title {
    position: absolute;
    top: 50%;
    transform: translate(0px, -50%);
    left: 10%;
    color: white;
}

@media (max-width: 576px) {
    .hero-title {
        font-size: 28px;
    }
}

@media (max-width: 756px) {
    .hero-title {
        font-size: 42px;
    }
}
@media (min-width: 1200px) {
    .hero-title {
        left: 260px;
    }
}