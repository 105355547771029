.wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.value {
    margin-right: 12px;
    font-size: 18px;
    color: white;
}