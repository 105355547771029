.amenities-block {
    padding: 18px;
    display: flex;
    justify-content: flex-start;
    border: 1px solid var(--xblue);
}

.amenities-block p {
    margin-right: 26px;

}

.amenities-block p * {
    font-size: 18px;

}

.amenities-block p span {

    vertical-align: middle;
}

.amenities-block p span svg {
    font-size: 16px;
}

.amenities-block p:last-child {
    margin-left: auto;
    margin-right: 0;
}

.amenities-block p span {
    margin-right: 8px; 
}