.main-section {
    padding: 131px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;    
    width: 100%;
}

@media only screen and (max-width: 767px) {
    .main-section {
        padding: 50px 0;
    }
}

.main-pane {
    max-width: 932px;
    box-sizing: border-box;
    margin-top: 120px;
    
}

.map-wrapper {
    height: 860px;
    overflow-x: hidden;    
    align-self: center;
}

.pagination-box {
    max-width: 100%;
    margin-top: 64px;
}

.no-listings-wrapper {
    text-align: center;
}
.no-listings-text {
    margin-top: 20px;
    display: inline-block;
}

.listings {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 576px) {
    .map-wrapper {
        max-width: 400px;
    }
}
@media (min-width: 576px) and (max-width: 992px) {
    .map-wrapper {
        max-width: 500px;
    }
}
@media (min-width: 992px)  {
    .main-section {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }
    
    .main-pane {
        margin-top: 0;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .map-wrapper {
        max-width: 500px;        
    }
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .map-wrapper {
        max-width: 700px;        
    }
}

@media (min-width: 1400px) {

    .main-section .listing-pane {
        display: grid;
        grid-template-columns: repeat(2, calc(50% - 1px)); 
    }
    .map-wrapper {
        max-width: 100%;
    }
}