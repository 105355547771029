.burger-line {
    height: 50px;
    width: 100%;
    background-color: var(--xdarkblue);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
}

.burger-line *, .link {
    color: white;
}

.link:first-child {
    border-top: 1px solid white;
}

.link {
    padding: 20px 30px;
    border-bottom: 1px solid white;
    cursor: pointer;
}

.link:hover {
    background-color: var(--xblue);
}

.burger-icon {
    cursor: pointer;
}

.menu {
    position: fixed;
    top: 50px;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: var(--xdarkblue);
    z-index: 50;
    padding: 10px 0;
}