.under-hero-details {
    padding-left: 10%;
    padding-top: 30px;
    padding-bottom: 30px;
    /* padding-top: 174px 0 89px 260px; */
    margin-top: -4px;
}

.item {
    margin-right: 112px;
}

.label {
    color: var(--xblue);
    text-transform: uppercase;
    margin-bottom: 18px;
}

.value {
    font-size: 24px;
    color: white;
}

.wrapper {
    position: relative;
    display: block;
}

@media (min-width: 992px) {
    .under-hero-details {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 174px 0 89px 260px;
        margin-top: -4px;
    }
}