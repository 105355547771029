.container {
    max-width: 811px;
}
.title {
    font-size: 28px;
    margin-bottom: 38px;
}
.description {
    line-height: 24px;
    color: var(--xgrey);
}

.features {
    /* display: grid;
    grid-template-columns: repeat(1, 1fr); */
}

.feature {
    
    border-bottom: 1px solid var(--xblue);
    padding: 24px 0;
    display: flex;
    justify-content: space-between;
}

.feature-value {
    color: var(--xgrey);
}

@media (min-width: 576px) {
    .title {
        font-size: 42px;
    }
    .features {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .feature {
        width: 45%;
    }
}

@media (min-width: 992px) {
    .under-hero-details {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 174px 0 89px 260px;
        margin-top: -4px;
    }
}