.listing-item-card {
    background-color: white;
    border: 1px solid var(--xblue);
    padding: 18px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 466px;
    max-width: 100vw;
    /*height: 587px;*/
    box-sizing: border-box;
    cursor: pointer;
}
.listing-item-card:hover {
    box-shadow: 0 0 15px rgba(68, 68, 68, 0.6);
    transition: box-shadow 0.2s ease-in-out;
}

.watch {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.img-block {
    position: relative; 
    overflow: hidden;  
    margin: 0;   
    padding: 0;
    height: 340px;
    width: 430px;
    max-width: 90vw;
}

.linear {
    position: absolute;
    bottom: 0;
    height: 50px;
    width: 100%;
    margin: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
}

.thumbnail {
    width: 100%;
    height: 100%; 
    object-fit: fill;
}

.listing-item-card > .description-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 16px 0;
    /*height: 109px;*/
}

.listing-item-card .place-title {
    font-size: 18px;
    color: var(--xblue);
    margin-bottom: 16px;
}

.listing-item-card .listing-title {
    font-size: 20px;
    text-align: center;
}

.listing-item-card > .amenities-block {
    padding: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wrapper {    
    position: absolute;
    left: 18px;
    bottom: 18px;
    z-index: 20;
}

@media (min-width: 576px) {
    .listing-item-card .listing-title {
        font-size: 28px;
    }
}