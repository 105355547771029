.info-box-wrapper{
    display: flex;
    justify-content: space-between;  
}

.guests-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.guests-row > * {
    width: 45%;
}

.info-item {
    margin-left: auto;
}

.submit-row > *{
    width: 100%;
}
.row-title {
    margin-bottom: 18px;
    color: var(--xblue);
}

.adult-children-row > div {
    width: 49%;
}

.adult-children-row > div:first-child {
    margin-right: 1px solid grey;
}

.wrapper{
    display: flex;
    justify-content: space-between;
}
.date-picker-box {
    width: 49%;
}
.total-row {
    font-size: 22px;
}
.total {
    color: var(--xblue);
}

.info-wrapper:not(:last-child) {
    margin-bottom: 18px;
}
.divider {
    width: 100%;
    height: 1px;
    background-color: var(--xdark);
    border: none; 
    margin-bottom: 32px;
}
.row {
    margin-bottom: 32px;
    
}