.footer-section {
    position: relative;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--xdarkblue);
}

.footer-section {
    font-size: 18px;
}

.footer-main {    
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo {
    margin-bottom: 20px;
}

.line {
    display: block;
    width: 100vw;
    height: 1px;
    background-color: #FFFFFF33;
}

.footer-legal {
    position: relative;
    width: 100%;
    padding: 28px 0;
    display: flex;
    flex-direction: column;
    align-items: center;    
}

.footer-legal * {
    color: #EFEFEF;
    font-weight: 400 !important;
}
.footer-legal > *:not(:last-child) {
    /*margin-bottom: 10px; */
}

.email-box {
    border: 1px solid #F0F0F080;
    color: #F0F0F080;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 20px;
    width: 240px;
    font-size: 16px;
}

@media (min-width: 992px) {
    .footer-logo {
        /*width: 5%;*/
    }
    .footer-main {    
        padding: 84px 0;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }
    .footer-legal {
        padding: 28px 0;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }
    .logo {
        margin-bottom: 0;
        width: 100px;
        height: 100px;
    }
    .all-rights-text {
        flex-grow: 1;
    }
    .link:not(:last-child) {
        margin-right: 64px;
    }
}

@media (min-width: 1400px) {
    .footer-main {
        width: var(--xxl);
   }
   .footer-legal {
        width: var(--xxl);
    }
}