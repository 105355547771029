.listing-details-page {
    position: relative;
}

.under-hero-section {
    background-color: var(--xdarkblue);
    position: relative;
}

.details-section, .amenities-section, .gallery-section {
    padding-left: 10%;
    padding-top: 30px;
    padding-bottom: 30px; 
    padding-right: 10%;    
}

.details-section, .amenities-section {
    background-color: var(--xlightgrey);
}

.gallery-section {
    background-color: var(--xdarkblue);
}

.you-may-like-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 128px 0;
}

@media (min-width: 1200px) {
    .details-section, .amenities-section, .gallery-section {
        padding: 84px 0 84px 260px;
    }
}

@media (min-width: 1400px) {
    .fixed-card-wrapper {
        right: 260px; 
        padding: 38px;
        background-color: white;
        z-index: 50;
        width: 448px;    
    }
    .relative-card-wrapper {
        display: none;
    }
}

@media (min-width: 1400px) and (max-width: 1750px) {
    .fixed-card-wrapper { 
        right: 20px; 
    }
}




@media (max-width: 1400px) {
    .relative-card-wrapper {
        width: 100%;
        padding: 38px;
    }
    .fixed-card-wrapper {
        display: none;
    }
}
