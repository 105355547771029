.header-section {
    width: 100%;
    display: flex;
    justify-content: center;
}

.header-wrapper {
    /*width: 100%;*/
}

.width-180 {
    width: 180px;
}

.header-container {    
    width: 100%;    
    display: flex;    
    justify-content: space-between; 
    align-items: center;   
    padding: 18px 0;
}

.header-logo {
    width: 80px;
    height: 80px;
    top: 2px;
    position: relative;
}

.header-book-now button {
    line-height: 18px;
    padding: 15px 46px 15px 46px;
}

.login {
    display: flex;
    align-items: center;
}

.login > span {
    font-size: 22px;
    margin-right: 12px;
}

/* media queries */

@media (min-width: 1400px) {
    .header-container {
        width: var(--xxl);
        align-self: center;
   }
    .header-wrapper {
        width: 1500px;
    }
}

@media (max-width: 1400px) {
    .header-wrapper {
        width: 100%;
   }
}

@media (max-width: 991px) {
    .header-wrapper {
        display: none;
    }
    .burger-wrapper {
       display: block; 
    }
}
@media (min-width: 992px) {
    .header-wrapper {
        display: block;
    }
    .burger-wrapper {
       display: none; 
    }
}