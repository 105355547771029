.title {
    margin-bottom: 32px;
    color: var(--xdark);
}
.row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
}
.row:not(:last-child) {
    margin-bottom: 32px;
}

.row-item {    
    width: 100%;
    position: relative;
}

.row-item.half-width {
    width: 49%;
}

.row-item.one-third-width {
    width: 32%;
}

.row-title {
    /*margin-bottom: 4px;*/
    color: var(--xblue);
}

.row-item:not(:first-child):before {
    content: "";
    position: absolute;
    width: 25px;
    height: 0;
    opacity: 0.2;
    border: 1px solid var(--xdark);
    transform: rotate(90deg);
    top: 50%;
    left: -20px; 
    margin-top: 10px; 
}

