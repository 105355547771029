.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    margin-bottom: 24px;
    font-size: 28px;
}

.description {
    margin-bottom: 64px;
    max-width: 100%;
    padding: 0 10px;
}

.listing-link:not(:first-child) {
    margin-left: -1px;
}

@media (min-width: 1200px) {
    .description {
        padding: 0;
    }
    .listings-box {
        display: flex;
    }
    .title {
        font-size: 42px;
    }
}