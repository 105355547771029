.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*margin: 0 20px;*/
}
.link {
    font-size: 18px;
}
.link:not(:last-child) {
    margin-right: 10%;
}
@media (min-width: 992px) {
    .link:not(:last-child) {
        margin-right: 54px;
    }
}